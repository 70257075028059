export enum ScreenCode {
  MANAGER_USER = "User",
  MANAGER_ACCOUNT_TYPE = "Permission",
  MANAGER_PRICE = "Price",
  SYSTEM_LOGS = "SystemLogs",
  ACCOUNT_TYPE = "AccountType",
  ADMINISTRATIVE_UNITS = "AdministrativeUnits",
  SOIL_TYPE = "SoilType",
  FILE_ATTACHMENT = "FileAttachment",
  LAND_PRICE_TABLE = "LandPriceTable",
  LAND_PRICE_FOR_EACH_PLOT = "LandPriceForEachPlot",
  TOOL = "Tool",
  TOOL_TYPE = "ToolType",
  MAP_LAYER = "MapLayer",
  MAP_DOCUMENTS = "MapDocuments",
  GUIDE = "Guide",
  FEEDBACK_LIST = "FeedbackList",
  RULES = "Rules",
  DOCUMENTS = "Documents",
  BASE_MAP = "BaseMap",
  COMMON_INFO = "CommonInfo",
  LAND_PRICE = "LandPrice",
  FEEDBACK_CONFIG = "FeedbackConfig",
  GOVERNMENT_PRICE = "GovernmentPrice",
  INVESTIGATE_PRICE = "InvestigatePrice",
  AUCTION_PROJECT = "AuctionProject",
  NEWS = "News",
}

export const SCREEN = {
  ManagerUser: {
    name: "Quản lý tài khoản",
    code: ScreenCode.MANAGER_USER,
  },
  ManagerAccountType: {
    name: "Quản lý loại tài khoản",
    code: ScreenCode.ACCOUNT_TYPE,
  },
  // ManagerPrice: {
  //   name: "Quản lý giá",
  //   code: ScreenCode.MANAGER_PRICE,
  // },
  SystemLogs: {
    name: "Nhật ký hệ thống",
    code: ScreenCode.SYSTEM_LOGS,
  },
  AdministrativeUnits: {
    name: "Quản lý đơn vị hành chính",
    code: ScreenCode.ADMINISTRATIVE_UNITS,
  },
  // SoilType: {
  //   name: "Quản lý loại đất",
  //   code: ScreenCode.SOIL_TYPE,
  // },
  // FileAttachment: {
  //   name: "Quản lý file đính kèm",
  //   code: ScreenCode.FILE_ATTACHMENT,
  // },
  // LandPriceTable: {
  //   name: "Quản lý bảng giá đất",
  //   code: ScreenCode.LAND_PRICE_TABLE,
  // },
  // LandPriceForEachPlot: {
  //   name: "Quản lý giá đất tới từng thửa đất",
  //   code: ScreenCode.LAND_PRICE_FOR_EACH_PLOT,
  // },
  Tool: {
    name: "Quản lý công cụ",
    code: ScreenCode.TOOL,
  },
  ToolType: {
    name: "Quản lý loại công cụ",
    code: ScreenCode.TOOL_TYPE,
  },
  MapLayer: {
    name: "Quản lý lớp bản đồ",
    code: ScreenCode.MAP_LAYER,
  },
  MapDocuments: {
    name: "Quản lý tài liệu bản đồ",
    code: ScreenCode.MAP_DOCUMENTS,
  },
  // News: {
  //   name: "Quản lý tin tức",
  //   code: ScreenCode.NEWS,
  // },
  Guide: {
    name: "Quản lý hướng dẫn",
    code: ScreenCode.GUIDE,
  },
  FeedbackList: {
    name: "Quản lý phản hồi",
    code: ScreenCode.FEEDBACK_LIST,
  },
  Rules: {
    name: "Quản lý điều khoản",
    code: ScreenCode.RULES,
  },
  Documents: {
    name: "Quản lý văn bản",
    code: ScreenCode.DOCUMENTS,
  },
  BaseMap: {
    name: "Quản lý bản đồ nền",
    code: ScreenCode.BASE_MAP,
  },
  CommonInfo: {
    name: "Quản lý thông tin chung",
    code: ScreenCode.COMMON_INFO,
  },
  // LandPrice: {
  //   name: "Quản lý giá đất",
  //   code: ScreenCode.LAND_PRICE,
  // },
  FeedbackConfig: {
    name: "Quản lý góp ý",
    code: ScreenCode.FEEDBACK_CONFIG,
  },
  // GovernmentPrice: {
  //   name: "Quản lý giá nhà nước",
  //   code: ScreenCode.GOVERNMENT_PRICE,
  // },
  // InvestigatePrice: {
  //   name: "Quản lý giá điều tra",
  //   code: ScreenCode.INVESTIGATE_PRICE,
  // },
  // AuctionProject: {
  //   name: "Quản lý dự án đấu giá",
  //   code: ScreenCode.AUCTION_PROJECT,
  // },
};
