export const API_URL = {
  Login: "api/Account/Login",
  Logout: "api/Account/Logout",
  Register: "api/Account/Register",
  ForgotPassword: "api/Account/ForgotPassword",
  ResetPasswordAccount: "api/Account/ResetPassword",
  GetUserAccountDetail: "api/Account/GetUserAccountDetail",

  GetHomePageMapViewData: "api/cms/AdminHomePage/GetHomePageMapViewData",
  UpdateLayer: "api/cms/AdminHomePage/UpdateLayerHaveImage",
  GetPgSchema: "api/admin/PgTable/get-pg-schema",

  GetListMapTool: "api/cms/AdminHomePage/GetListMapTool",
  GetLookupMapTool: "api/cms/AdminHomePage/GetLookupMapTool",
  GetDetailMapTool: "api/cms/AdminHomePage/GetDetailMapTool",
  UpdateMapTool: "api/cms/AdminHomePage/UpdateMapTool",

  GetLookupCategory: "api/cms/AdminHomePage/GetLookupCategory",
  CreateToolCategory: "api/cms/AdminHomePage/CreateMapToolCategory",
  UpdateCategory: "api/cms/AdminHomePage/UpdateCategory",
  DeleteCategory: "api/cms/AdminHomePage/DeleteMapToolCategory",

  GetUserManagement: "api/admin/User/GetUserManagement",
  GetUserDetail: "api/admin/User/GetUserDetail",
  CreateUser: "api/admin/User/CreateUser",
  UpdateUser: "api/admin/User/UpdateUser",
  ActiveUser: "api/admin/User/ActiveUser",
  DeActiveUser: "api/admin/User/DeActiveUser",
  ResetPassword: "api/admin/User/ResetPassword?UserId=",
  DeleteUser: "api/admin/User/DeleteUser",

  GetTutorials: "api/cms/AdminIntroduce/GetTutorials",
  GetTerms: "api/cms/AdminIntroduce/GetTerms",
  UpdateTutorials: "api/cms/AdminIntroduce/UpdateTutorials",
  UpdateTerms: "api/cms/AdminIntroduce/UpdateTerms",

  GetMapPlanningByFolderId: "api/cms/AdminMapPlanning/GetMapPlanningByFolderId",
  GetMapPlanningFolders: "api/cms/AdminMapPlanning/GetMapPlanningFolders",
  CreateMapPlanningCategory:
    "api/cms/AdminMapPlanning/CreateMapPlanningCategory",
  DeleteMapPlanningCategory:
    "api/cms/AdminMapPlanning/DeleteMapPlanningCategory",
  CreateMapPlanning: "api/cms/AdminMapPlanning/CreateMapPlanning",
  UpdateMapPlanning: "api/cms/AdminMapPlanning/UpdateMapPlanning",
  DeleteMapPlanning: "api/cms/AdminMapPlanning/DeleteMapPlanning",

  GetMapDocumentByFolderId: "api/cms/AdminMapDocument/GetMapDocumentByFolderId",
  GetMapDocumentFolders: "api/cms/AdminMapDocument/GetMapDocumentFolders",
  CreateMapDocument: "api/cms/AdminMapDocument/CreateMapDocument",
  UpdateMapDocument: "api/cms/AdminMapDocument/UpdateMapDocument",
  DeleteMapDocument: "api/cms/AdminMapDocument/DeleteMapDocument",
  CreateMapDocumentCategory:
    "api/cms/AdminMapDocument/CreateMapDocumentCategory",
  DeleteMapDocumentCategory:
    "api/cms/AdminMapDocument/DeleteMapDocumentCategory",

  GetListFeedbacks: "/api/cms/AdminFeedback/GetListFeedbacks",

  CreateBaseMap: "api/cms/AdminBaseMapSetting/Create",
  UpdateBaseMap: "api/cms/AdminBaseMapSetting/Update",
  DeleteBaseMap: "api/cms/AdminBaseMapSetting/Delete",
  GetListBaseMapSetting: "api/cms/AdminBaseMapSetting/GetListBaseMapSetting",
  ValidateLayerType: "api/cms/AdminBaseMapSetting/ValidateLayerType",

  GetWebCommonViewData: "api/cms/AdminHomePage/GetWebCommonViewData",
  UpdateWebCommon: "api/cms/AdminHomePage/UpdateWebCommon",
  GetFeedbackContent: "api/cms/AdminFeedback/GetFeedbackContent",
  UpdateFeedbackContent: "api/cms/AdminFeedback/UpdateFeedbackContent",

  GetListNews: "api/cms/AdminNews/GetListNews",
  GetDetailNews: "api/cms/AdminNews/GetDetailNews",
  CreateNews: "api/cms/AdminNews/CreateNews",
  UpdateNews: "api/cms/AdminNews/UpdateNews",
  DeleteNews: "api/cms/AdminNews/DeleteNews",

  SearchPlot: "api/cms/AdminHomePage/SearchPlot?",
  GetListPlotTemp: "/api/cms/AdminHomePage/GetListPlotTemp",
  GetDetailStandardPlot: "/api/cms/PlotTemp/GetDetailExpertisePlot",
  ApprovePlotTemp: "/api/cms/PlotTemp/ApprovePlotTemp",
  RejectPlotTemp: "/api/cms/PlotTemp/RejectPlotTemp",

  UpdateListStateLandPrice: "/api/cms/StateLandPrice/UpdateListStateLandPrice",
  UpdateListInvestigatePrice:
    "/api/cms/StateLandPrice/UpdateListInvestigatePrice",

  GetListWards: "api/cms/AdminLandTitleConfig/GetListLandTitleConfig",
  GetDetailWards: "api/cms/AdminLandTitleConfig/GetDetailLandTitleConfig?id=",
  CreateWards: "api/cms/AdminLandTitleConfig/CreateLandTitleConfig",
  UpdateWards: "api/cms/AdminLandTitleConfig/UpdateLandTitleConfig",
  DeleteWards: "api/cms/AdminLandTitleConfig/DeleteLandTitleConfig?ids=",

  ChangePassword: "/api/Account/ChangePassword",

  GetListAccountType: "/api/cms/Role/GetListRole",
  CreateAccountType: "/api/cms/Role/CreateRole",
  DeleteAccountType: "/api/cms/Role/DeleteRole?id=",
  UpdateAccountType: "/api/cms/Role/UpdateRole",
  GetScreenLookup: "/api/cms/Role/GetScreenLookup",
  GetAccountTypeLookup: "/api/cms/Role/GetRoleLookup",

  GetListAuctionProject: "/api/cms/AdminAuctionProject/GetListAuctionProject",
  CreateAuctionProject: "/api/cms/AdminAuctionProject/CreateAuctionProject",
  UpdateAuctionProject: "/api/cms/AdminAuctionProject/UpdateAuctionProject",
  GetDetailAuctionProject:
    "/api/cms/AdminAuctionProject/GetDetailAuctionProject?id=",
  DeleteAuctionProject:
    "/api/cms/AdminAuctionProject/DeleteAuctionProject?ids=",

  GetListLandType: "/api/cms/AdminLandType/GetListLandType",
  CreateLandType: "/api/cms/AdminLandType/CreateLandType",
  UpdateLandType: "/api/cms/AdminLandType/UpdateLandType",
  GetDetailLandType: "/api/cms/AdminLandType/GetDetailLandType?id=",
  DeleteLandType: "/api/cms/AdminLandType/DeleteLandType?ids=",

  GetListUserActionLog: "/api/cms/AdminUserActionLog/GetListUserActionLog",
  ExportListUserActionLog:
    "/api/cms/AdminUserActionLog/GetExportListUserActionLog",
  DeleteLogs: "/api/cms/AdminUserActionLog/DeleteLogs?Ids=",
  GetMasterDataFilter: "/api/cms/AdminUserActionLog/GetMasterDataFilter",

  GetListAttachmentFiles:
    "/api/cms/AdminAttachmentManagement/GetListAttachmentManagement",
  CreateAttachmentFile:
    "/api/cms/AdminAttachmentManagement/CreateAttachmentManagement",
  UpdateAttachmentFile:
    "/api/cms/AdminAttachmentManagement/UpdateAttachmentManagement",
  GetDetailAttachmentFile:
    "/api/cms/AdminAttachmentManagement/GetDetailAttachmentManagement?id=",
  DeleteAttachmentFile:
    "/api/cms/AdminAttachmentManagement/DeleteAttachmentManagement?ids=",

  GetLandPriceTableList: "/api/cms/AdminLandPrice/GetListLandPrice",
  GetLandPriceTableDetail: "/api/cms/AdminLandPrice/GetDetailLandPrice?id=",
  CreateLandPriceTable: "/api/cms/AdminLandPrice/CreateLandPrice",
  UpdateLandPriceTable: "/api/cms/AdminLandPrice/UpdateLandPrice",
  DeleteLandPriceTable: "/api/cms/AdminLandPrice/DeleteLandPrice?ids=",

  GetLandPriceForEachPlotList:
    "/api/cms/AdminLandPriceParcel/GetListLandPriceParcel",
  GetLandPriceForEachDetail:
    "/api/cms/AdminLandPriceParcel/GetDetailLandPriceParcel?id=",
  CreateLandPriceForEach: "/api/cms/AdminLandPriceParcel/CreateLandPriceParcel",
  UpdateLandPriceForEach: "/api/cms/AdminLandPriceParcel/UpdateLandPriceParcel",
  DeleteLandPriceForEach:
    "/api/cms/AdminLandPriceParcel/DeleteLandPriceParcel?ids=",
  SyncLandPriceForEach: "/api/cms/AdminLandPriceParcel/SyncToMainTable?id=",
  ReadShapeFile: "api/admin/Utils/import-shape-file-read-dbf",
  BulkInsertLandPriceParcel:
    "api/cms/AdminLandPriceParcel/BulkInsertLandPriceParcel"
};

export const API_URL_ROOT = "https://api-quangninh.cargis.vn/";
