export * from "./Router";
export * from "./ApiUrl";
export * from "./LocalStorageKey";
export * from "./ActionsTypes";
export * from "./Pagination";
export * from "./Message";

export const FILE_TYPE = {
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
};

export const MAX_ROWS_ALLOW_UPLOAD = 10000;

export const GOVERNMENT_PRICE_TEMPLATE_CONFIG = {
  INDEX_COL: "A",
  ROAD_CODE_COL: "E",
  PRICE_COL: "F"
};

export const INVESTIGATE_PRICE_TEMPLATE_CONFIG = {
  INDEX_COL: "A",
  MAP_INDEX: "B",
  LAND_INDEX: "C",
  PRICE: "D"
};

export const PROJECT_DOCUMENT_FOLDER_NAME = "Hồ sơ dự án";
